@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Circular Book";
  src: url("/public/assets/fonts/Circular-Book.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Circular";
  src: url("/public/assets/fonts/Circular-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Paul Signature";
  src: url("/public/assets/fonts/PaulSignature.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Circular Book", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
